import React from 'react'
import Helmet from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'
import favicon from '../../static/favicon.png'

const SEO = ({title}) => {
  const data = useStaticQuery(graphql`
    query GlobalMetaDataQuery {
      allSanityGlobalMetaData {
        nodes {
          ogImage {
            asset {
              url
            }
          }
          keywords
          description
          siteTitle
        }
      }
    }
  `)

  if (data.allSanityGlobalMetaData.nodes.length < 1) return null

  const {
    description,
    ogImage,
    keywords,
    siteTitle
  } = data.allSanityGlobalMetaData.nodes[0]

  return (
    <Helmet
      htmlAttributes={{lang: 'de'}}
      title={title ? `${title} | ${siteTitle}` : siteTitle}
      link={[
        {rel: 'shortcut icon', type: 'image/png', href: `${favicon}`, size: '32x32'}
      ]}
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          property: 'og:title',
          content: siteTitle
        },
        {
          property: 'og:description',
          content: description
        },
        {
          property: 'og:image',
          content: ogImage && ogImage.asset && ogImage.asset.url
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: siteTitle
        },
        {
          name: 'twitter:title',
          content: siteTitle
        },
        {
          name: 'twitter:description',
          content: description
        }
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', ')
            }
            : []
        )
      }
    />
  )
}

export default SEO
