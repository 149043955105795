import {Link} from 'gatsby'
import React, {useState} from 'react'
import Logo from '../icon/logo'
import Headroom from 'react-headroom'
import MenuOverlay from '../menuOverlay/menuOverlay'

import './header.scss'

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <header>
      <Headroom>
        <div className='container-fluid'>
          <div className='container'>
            <div className='row pt-3 pt-md-4'>
              <Link to='/' className='logo col-7'>
                <Logo />
              </Link>
              <div className='menu-btn d-md-none d-flex mr-2'>
                <button className='btn-menu-warpper' onClick={() => setMenuOpen(!menuOpen)}>
                  <span className='btn-menu'>
                    <span className='inner' />
                  </span>
                </button>
                {
                  menuOpen &&
                  <MenuOverlay toggle={() => setMenuOpen(!menuOpen)} />
                }
              </div>
              <nav className='font-size-small uppercase bold col-4'>
                <ul className='d-none d-md-flex'>
                  <li >
                    <Link className='text-link-Underline' to='/projekte/'>Projekte</Link>
                  </li>
                  <li >
                    <Link className='text-link-Underline' to='/unternehmen/'>Unternehmen</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Headroom>
    </header>
  )
}
export default Header
