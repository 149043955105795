import React from 'react'
import {Link, useStaticQuery, graphql} from 'gatsby'
import Portal from '../portal'
import Logo from '../icon/logo'
import Chevron from '../chevron/chevron'

import './menuOverlay.scss'

const MenuOverlay = ({toggle}) => {
  const data = useStaticQuery(graphql`
    query MenuOverlayQuery {
      allSanityFooter {
        nodes {
          contact {
            email
            headline
            phone
          }
        }
      }
    }
  `)

  const {contact} = data.allSanityFooter.nodes[0]

  const svg = <svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='none' stroke='#FFFFFF' stroke-miterlimit='10' d='M1.1 1.1l27.7 27.7M28.8 1.1L1.1 28.8'/></svg>

  return (
    <Portal>
      <div className='menu-overlay'>
        <div className='container-fluid'>
          <div className='container'>
            <div className='row nav pt-3 pt-md-4'>
              <Link to='/' className='logo col-7'>
                <Logo variant='negative' />
              </Link>
              <nav className='col-4 font-size-small uppercase bold'>
                <button className='btn-menu-warpper' onClick={toggle}>
                  <span className='btn-menu'>
                    {svg}
                  </span>
                </button>
              </nav>
            </div>
            <div className='row mt-1 menu'>
              <ul className='col-11 light font-size-large'>
                <li >
                  <Link className='' to='/projekte/'>Projekte</Link>
                </li>
                <li >
                  <Link className='' to='/unternehmen/'>Unternehmen</Link>
                </li>
              </ul>
            </div>
            <div className='row mt-1 contact'>
              <ul className='col-11 light font-size-medium'>
                <li className='ml-1'>
                  <a href={`mailto:${contact.email}`}>
                    <Chevron size='small' color='secondary-muted' className='mr-2' />
                    {contact.email}
                  </a>
                </li>
                <li >
                  <a href={`tel:${contact.phone}`}>
                    <Chevron size='small' color='secondary-muted' className='mr-2' />
                    {contact.phone}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default MenuOverlay
