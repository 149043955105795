import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'

import './footer.scss'
import Chevron from '../chevron/chevron'

const Layout = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allSanityFooter {
        nodes {
          address {
            googleMapsLink
            headline
            name
            streetAndNumber
            postalAndCity
          }
          contact {
            email
            headline
            phone
          }
        }
      }
    }
  `)

  if (data.allSanityFooter.nodes.length < 1) return null

  const {address, contact} = data.allSanityFooter.nodes[0]

  return (
    <footer>
      <div className='container-fluid'>
        <div className='container'>
          <div className='contect-row row'>
            <div className='contact col-xs-1 col-md-7 pt-4 pb-4'>
              <h2>{contact && contact.headline}</h2>
              <div>
                <p>
                  <a href={`mailto:${contact && contact.email}`}>
                    <Chevron size='small' color='secondary-muted' className='mr-2' />
                    {contact && contact.email}
                  </a>
                </p>
                <p>
                  <a href={`tel:${contact && contact.phone}`}>
                    <Chevron size='small' color='secondary-muted' className='mr-2' />
                    {contact && contact.phone}
                  </a>
                </p>
              </div>
            </div>
            <div className='address col-11 col-md-4 pb-4'>
              <h2 className='pt-4'>{address && address.headline}</h2>
              <div>
                <a href={address && address.googleMapsLink}>
                  <p>{address && address.name}</p>
                  <p>{address && address.streetAndNumber}</p>
                  <p>{address && address.postalAndCity}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='menu-row container-fluid'>
        <div className='circle' />
        <div className='container'>
          <div className='row'>
            <div className='menu font-size-small col-11 offset-0 col-md-4 offset-md-7 pb-4'>
              <p>© {new Date().getFullYear()} Becks Hausbau GmbH, <Link to="/impressum">Impressum</Link></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Layout
