import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import SEO from '../seo'
import PropTypes from 'prop-types'

const Layout = ({children, title, className, footer}) => (
  <>
    <Header />
    <SEO title={title} />
    <main className={className}>
      {children}
    </main>
    {
      footer &&
      <Footer />
    }
  </>
)

export default Layout

Layout.propTypes = {
  footer: PropTypes.bool
}

Layout.defaultProps = {
  footer: true
}
