/* eslint-disable react/no-unknown-property */
import React from 'react'
import PropTypes from 'prop-types'

import './chevron.scss'

const Chevron = ({color, size, rotation, className}) => { 
  const styles = `color-${color} size-${size} rotation-${rotation}`
  const svg = <svg version={1.0} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path fill='none' stroke='#000' strokeWidth='.726' strokeMiterlimit={10} d='M10 1l14 14-14 14' /></svg>

  return (
    <span className={`chevron-wrapper ${styles} ${className}`}>
      {svg}
    </span>
  )
}

export default Chevron

Chevron.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  rotation: PropTypes.string
}

Chevron.defaultProps = {
  color: 'primary',
  size: 'medium',
  rotation: '0'
}
